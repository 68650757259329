main{
    min-height: 80vh;
}

#applicaitonTab, #adminDashBoardNav .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: #223e87;
    color: #fff;
}

#applicaitonTab, #adminDashBoardNav .nav-pills .nav-link
{
    border-radius: 0px;
    background-color:#b4b4b4;
    color:black;
}

.change-pass-screen {
    overflow-x: hidden;
}

.me-1
{
    padding-bottom: 0.5em;
}

.form-control
{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #b4b4b4;
    border-radius: 0rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.footer-text
{
  left: 0;
  bottom: 0;
  padding: 0;
}

input[type=checkbox]
{
  width: 1.1em;
  height:1.1em;
}

@media (max-width: 768px) 
{
    #adminHeader 
    {
        flex-direction: column-reverse;
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    #dashboardTitle h3
    {
        float: left !important;
        width: max-content;
    }

    #adminHeader
    {
        flex-direction: column-reverse;
    }

    #adminDashBoardNav
    {
        flex-direction: row;
        width: fit-content;
    }

    #adminDashBoardNav .nav-item
    {
        width: auto;
    }

    #navEquipmentPills
    {
        flex-wrap: nowrap;
        height: max-content;
    }

    #navEquipmentPills .nav-item
    {
        align-items: center;
    }

    #navEquipmentPills .nav-item a
    {
        height: 100%;
        text-align: center;
    }
}

@media (max-width: 990px)
{
    .nav-item
    {
        border-style: solid;
        border-color: white;
        border-width: thin;
    }
}