/* .row {
    padding-right: 10px;
    padding-left: 10px;
} */

@media (max-width: 425px) {
    .applicationSubHeader {
        font-size: 16px;
    }

    .addEquipmentButton {
        width: 100%;
        font-size: 16px;
    }
    
    .MuiTypography-root.MuiTypography-h6 {
        font-size: 16px;
    }

    .form-check-label, .disposalReceipt {
        font-size: 14px;
    }
}