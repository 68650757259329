#homeScreen 
{
  background-image: linear-gradient( to top, rgb(34, 62, 135, 1), rgba(15, 54, 153, 0.7)), url('firstbackground.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  overflow: auto;
}

.footer-text
{
  left:0;
  bottom: 0;
}

h1,
h2,
h4,
p,
a 
{
  font-family: "Montserrat Bold", sans-serif;
}

.btn .btn-success{
  background-color:rgb(139, 196, 63) !important
}

#applyOnlineBtn .btn-group-lg > .btn,
.btn-lg {
  border-radius: 0.5rem;
  width: fit-content;
}

#applyHead {
  font-size: 35px;
  margin-top: 3%;
  font-weight: 780;
}

#applyCaption {
  font-size: 15px;
}

@media (min-width: 768px) and (min-height: 850px) {
  .main-content
  {
    margin-top: -14em;
    display: flex;
    align-items: center;
  }
}