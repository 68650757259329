.document-pdf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


@media screen and (max-width: 992px) {
  .document-pdf{
    align-items:flex-start;
 }
}