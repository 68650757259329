.MTableHeader-header-13 {
  background-color: #223e87 !important;
  color: #fff !important;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.MuiTableCell-head {
  background-color: #223e87 !important;
  color: #fff !important;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}


.MuiCheckbox-colorSecondary.Mui-checked
{
  color : #000D4D !important;
}

.MTableToolbar-highlight-6
{
  color : #000D4D !important;
  background-color: #f1f5ff !important;
}

.jss6
{
  color : #000D4D !important;
  background-color: #f1f5ff !important;
}