@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body,
#root,
#root > div {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Montserrat" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
u,
a,
p,
b,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Montserrat" !important;
}
main {
  min-height: 80vh;
}

.ag-theme-alpine {
  font-family: "Montserrat" !important;
}

.MTableHeader-header-13 {
  background-color: #223e87 !important;
  color: #fff !important;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.MuiTableCell-head {
  background-color: #223e87 !important;
  color: #fff !important;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.nav-item {
  cursor: pointer;
}

.fa-question-circle {
  color: #666666;
}

.table {
  /* display: block !important; */
  overflow-x: auto !important;
  width: 100% !important;
  display: table !important;
}

.table td {
  word-break: break-word;
}

.table2 {
  /* display: block !important; */
  overflow-x: auto !important;
  width: 100% !important;
  display: table !important;
}

.table2 th {
  padding: 15px;
}

.table3 {
  /* display: block !important; */
  overflow-x: auto !important;
  width: 100% !important;
  display: table !important;
}

.table2 td {
  word-break: break-word;
  padding: 8px;
}

@media screen and (max-width: 1403px) {
  .table {
    display: block !important;
    overflow-x: auto !important;
    width: 100% !important;
  }
  .table td {
    word-break: break-word;
    padding: 8px;
  }

  .table2 {
    display: block !important;
    overflow-x: auto !important;
    width: 100% !important;
  }
  .table2 th {
    padding: 15px;
  }

  .table2 td {
    word-break: break-word;
    padding: 8px;
  }
}

@media screen and (min-width: 1407px) {
  .table2 {
    display: block !important;
    overflow-x: auto !important;
    width: 100% !important;
  }
  .table2 td {
    word-break: break-word;
  }
}

@media screen and (min-width: 2240px) {
  .table2 {
    display: table !important;
    overflow-x: auto !important;
    width: 100% !important;
  }
  .table2 th {
    padding: 15px;
  }
  .table2 td {
    word-break: break-word;
    padding: 10px;
  }
}

.w-125 {
  width: 12.5%;
}

.w-100px {
  width: 100px;
}

.w-200px {
  width: 200px;
}

.h-350px {
  height: 350px;
}

.h-500px {
  height: 500px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.highlight-item .highlight-item-child {
  display: flex;
}

.my-custom-button .dropdown-toggle::after {
  display: none !important; 
}

.my-custom-radio input[type="radio"]:checked {
  border-color: #223e87;
  background-color: #223e87;
}

.my-custom-radio input[type="radio"]:focus {
  border-color: #223e87;
  background-color: #223e87;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0);
}

.my-form-select:focus {
  outline: none;
  border-color: #223e87;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0);
}

.my-form-control:focus {
  outline: none;
  border-color: #223e87;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0);
}