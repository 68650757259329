#payts.swal2-height-auto{
    height:100% !important;
}


@media only screen and (max-width: 1086) {
    td{
    word-break:break-word !important;
}

}
/* @media only screen and (min-width: 2239) {
  canvas.react-pdf__Page__canvas {
    width: 1200px !important;
  }

  .react-pdf__Page__canvas {
    width: 1200px !important;
  }

}
 */
