#StepsContent {
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-width: thin;
}

#StepsContent li {
  width: 6.5em;
  height: 5em;
  text-align: center;
  line-height: 2em;
  border-radius: 1em;
  margin: 0 1em;
  display: inline-block;
  color: white;
  position: relative;
}
  
#StepsContent li span{
  position: absolute;
  color: black;
  font-size: 0.7rem;
  line-height: normal;
}

#one{
  top:71px;
  left: -5px;
}

#two{
  top:71px;
  left:-2px;
}

#three{
  top:71px;
  left:2px;
}

#four{
  top:71px;
  left:0px;
}

#five{
  top:71px;
  left:7px;
}

#six{
  top:71px;
  left:2px;
}

#seven{
  top:71px;
  left:23px;
}

#eight{
  top:71px;
  left:2px;
}
  
#StepsContent li::before{
  content: '';
  position: absolute;
  top: 2em;
  left: -4em;
  width: 6.5em;
  height: .1em;
  background: lightgrey !important;
  z-index: -1;
}
  
  
#StepsContent li:first-child::before 
{
  display: none;
}

#StepsContent .active 
{
  background: dodgerblue;
}

#StepsContent .active ~ li 
{
  background: lightblue;
}

#StepsContent .active ~ li::before 
{
  background: lightblue;
}

#mobileStep{
  font-size: 12px;
}

#stepsBackground
{
  padding-bottom: 5vh;
  background:#ebebeb;
}

@media (min-width:425px) {
  #stepsBackground {
    min-width: max-content
  }
}