.editButton {
    border-color: transparent;
    background-color: transparent;
    color: #223E87
}

.editButton:hover, .editButton:active {
    color: rgba(18, 46, 122, 0.726);
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active
{
    color: #ffffff;
    background-color: #223e87;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-link {
    color: rgba(0, 0, 0, 1);
    width: 100%;
}

.nav-item {
    background-color: #D0D5DB;
    top: 100px;
}

.title {
    color: #929292;
}

.card-header-tabs
{
    margin-right: -1rem;
    margin-bottom: -.75rem;
    margin-left: -1rem;
    border-bottom: 0;
}

.MuiTableRow-root .MuiTableRow-head
{
    background-color: #223E87 
}

@media only screen and (max-width: 800px) {
    .customTable {
        font-size: 12px;
    }
}