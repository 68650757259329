.main {
    width: 100%;
    margin: 0;
}

.card {
    width: 100%;
}

table {
    width: fit-content;
    table-layout: fixed;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: #eee!important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color:rgb(0, 78, 0)!important
}

.applicationTitle {
    font-size: 16px;
    font-weight: bold;
}

#accountName {
    color: #223e87;
}

@media (max-width: 425px) {
    .applicationTitle {
        font-size: 14px;
        font-weight: 600;
    }

    .requiredField {
        font-size: 12px;
    }
}
    
@media (max-width: 993px) and (min-width: 766px) {
    .form-label{
        height: 2.5em;
    }
}