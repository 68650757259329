#homeScreen2
{
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  overflow: auto;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  margin: auto;
}

.bottomButton {
  padding: 0 2em 0 2em;
}

#cancelButton {
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 425px) {
  .buttonGroup {
    /* m-0 px-4 d-flex flex-column align-items-center */
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1.5em 0 1.5em;
  }

  .bottomButton {
    width: 100%;
    font-size: 1rem;
  }
}

.footer-text
{
  left:0;
  bottom: 0;
}


.colored-toast.swal2-icon-success {
    background-color: #a5dc86 !important;
  }
  
  .colored-toast.swal2-icon-error {
    background-color: #f27474 !important;
  }
  
  .colored-toast.swal2-icon-warning {
    background-color: #f8bb86 !important;
  }
  
  .colored-toast.swal2-icon-info {
    background-color: #3fc3ee !important;
  }
  
  .colored-toast.swal2-icon-question {
    background-color: #87adbd !important;
  }
  
  .colored-toast .swal2-title {
    color: white;
  }
  
  .colored-toast .swal2-close {
    color: white;
  }
  
  .colored-toast .swal2-html-container {
    color: white;
  }
  