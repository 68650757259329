.form-control {
  height: auto;
}

#applicationTableContainer {
  height: fit-content;
}

.agGridTable {
  height: 400px !important;
}

#applicationFormNa .nav-pills .nav-link {
  border-radius: 0px;
  color: black;
  background-color: #d0d5db;
}

#applicationFormNa .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #223e87;
  color: #fff;
}

#applicationFormNav .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  /* background-color: #223e87;
    color: #fff; */
  border-bottom: 5px solid #223e87;
  color: black;
}

#applicationFormNav .nav-pills .nav-link {
  border-radius: 0px;
  background-color: #ffffff;
  color: black;
}

#commentCanvas {
  height: 60vh !important;
}

.ag-header-viewport {
  background-color: #223e87 !important;
  color: #fff !important;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.ag-header-cell-text {
  color: #ffffff;
}

.btn-group-vertical button {
  border-radius: 0.25rem !important;
}

@media (max-width: 398px) {
  .rejectbtn {
    margin-top: 5px;
    width: 100%;
    margin-bottom: 10px;
  }
  .processbtn {
    width: 100%;
  }
}

@media (max-width: 990px) {
  .editbtn {
    margin: 0;
    width: 100% !important;
    height: 40px;
  }
  .customRow {
    flex-shrink: 0 !important;
    width: 100% !important;
  }
}
