.main-content
{
    height: auto !important;
}

.footer-text
{
    left:0;
    bottom: 0;
    width: 100%;
}