#homeScreen2
{
    background-image: linear-gradient( to top, rgb(34, 62, 135, 0.6), rgba(15, 54, 153, 0.7)), url('secondbackground.png');
    background-repeat:no-repeat;
    background-size:100% 100%;
}

.mt-5{
    margin-top: 4rem!important;
}

@media (min-width: 768px) and (min-height: 1024px) {
    .main-content
    {
        display: flex;
        align-items: center;
    }

    #formContent
    {
        margin-top: -14em;
    }
  }

.btn
{
    font-family: 'Montserrat Bold', sans-serif
} 